import { useOpenVideoPreview } from '/features/pageOnly/wavemakers/videoPreviewHooks'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

/** @param {{ state: 'expanded' | 'collapsed' | 'closed', layoutClassName?: string }} _ */
export function Anchor({ state, layoutClassName = undefined }) {
  const { ref: visibilityRef, isInViewport } = useIsInViewport({ rootMargin: '0%' })
  const open = useOpenVideoPreview()

  React.useEffect(
    () => { open(state) },
    [state, open, isInViewport]
  )

  return (
    <div ref={visibilityRef} className={layoutClassName} />
  )
}
